<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p
            class="card-header-title has-text-light is-uppercase"
          >KEGIATAN HARIAN</p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            label="Rotasi"
            custom-class="is-small"
            :type="{'is-danger': errorMap.stase}"
            :message="errorMap.stase"
          >
            <generic-select
              v-model="kegiatan.stase"
              apiPath="/stase/options/"
              :default="true"
              @input="validateInput('stase')"
            ></generic-select>
          </b-field>

          <b-field
            label="Tanggal"
            class="tgl-field"
            custom-class="is-small"
            :type="{'is-danger': errorMap.tanggal}"
            :message="errorMap.tanggal"
          >
            <date-picker v-model="kegiatan.tanggal" @input="validateInput('tanggal')"></date-picker>
          </b-field>

          <b-field
            label="Jenis"
            custom-class="is-small"
            :type="{'is-danger': errorMap.jenis}"
            :message="errorMap.jenis"
          >
            <b-select v-model="kegiatan.jenis" 
              @input="validateInput('jenis')">
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
            </b-select>
          </b-field>

          <b-field
            v-if="showLokasi"
            label="Lokasi"
            custom-class="is-small notrequired"
            :type="{'is-danger': errorMap.lokasi}"
            :message="errorMap.lokasi"
          >
            <b-field class="ac-field">
              <generic-autocomplete
                placeholder="Cari sebelum buat baru"
                apiPath="/perawatan/lokasi/"
                v-model="kegiatan.lokasi"
                @input="validateInput('lokasi')"
                @typing="onLokasiACTyping"
                :required="false"
              ></generic-autocomplete>

              <div class="control" v-if="isCreate || isLoaded">
                <router-link :to="lokasiAddTo">
                  <b-button class="is-primary">
                    <b-icon icon="plus" />
                  </b-button>
                </router-link>
              </div>
            </b-field>
          </b-field>

          <b-field
            label="Dosen"
            custom-class="is-small"
            :type="{'is-danger': errorMap.preceptor}"
            :message="errorMap.preceptor"
          >
            <generic-autocomplete
                apiPath="/anggota/dosen/"
                v-model="kegiatan.preceptor"
                @input="validateInput('preceptor')"
              ></generic-autocomplete>
          </b-field>

          <b-field
            label="Kegiatan"
            custom-class="is-small"
            :type="{'is-danger': errorMap.kegiatan}"
            :message="errorMap.kegiatan"
          >
            <b-input v-model="kegiatan.kegiatan" @input="validateInput('kegiatan')"></b-input>
          </b-field>

          <b-field
            label="Uraian Kegiatan"
            custom-class="is-small notrequired"
            :type="{'is-danger': errorMap.catatan}"
            :message="errorMap.catatan"
          >
            <b-input v-model="kegiatan.catatan" type="textarea" @input="validateInput('catatan')"></b-input>
          </b-field>

          <b-field
            v-if="isCreate"
            class="file"
            :type="{'is-danger': errorMap.foto}"
            :message="errorMap.foto"
          >
            <b-upload v-model="fotoMdl" @input="resize" accept=".jpg, .jpeg, .png">
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Upload Foto</span>
              </a>
            </b-upload>
            <span class="file-name" v-if="fotoMdl">{{ fotoMdl.name }}</span>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject(!isCreate || !fotoMdl)"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import KegiatanPSPD from "../models/cuKegiatanPSPD.js";
import { mapActions, mapState } from "vuex";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";

export default {
  name: "KegiatanPSPDCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue")
  },
  data() {
    this.kegiatanMdl = new KegiatanPSPD();
    this.objectMdl = this.kegiatanMdl; // alias yg digunakan di cuMixin
    let obv = this.kegiatanMdl.getObservables();
    obv.keterampilanInput = "";
    obv.lokasiInput = "";
    obv.foto = null;
    obv.fotoMdl = null;
    this.uploadPhoto = new UploadPhoto("kegiatanpspd");
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapState("aktivitas", { kegiatanLokasiStase: "kegiatanLokasi" }),
    lokasiAddTo() {
      const kegiatan = this.getkegiatan();
      return {
        name: "lokasi-add",
        params: { kegiatanLokasi: kegiatan }
      };
    },
    showLokasi() {
      return this.kegiatan.jenis == "Offline";
    }
  },
  methods: {
    ...mapActions("aktivitas", ["setKegiatanLokasi"]),
    onLokasiACTyping(value) {
      this.lokasiInput = value;
    },
    getkegiatan() {
      const kegiatan = JSON.parse(JSON.stringify(this.kegiatan));
      if (!this.isCreate) {
        kegiatan["edited"] = this.kegiatanMdl.getEdited();
      }
      return kegiatan;
    },
    fetchData() {
      this.kegiatanMdl.addPayloadData({pspd: this.userId});
      if (this.kegiatanLokasiStase) {
        this.kegiatan = JSON.parse(JSON.stringify(this.kegiatanLokasiStase));
        if (this.isCreate) {
          this.kegiatan.foto = null;
          this.uploadPhoto.requestAWS();
        } else {
          this.setEdited(this.kegiatan["edited"]);
        }

        for (const field of this.kegiatanMdl.getRequiredFields()) {
          if (this.kegiatan[field]) {
            this.validateInput(field);
          }
        }
        this.setKegiatanLokasi(null);
      } else if (!this.isCreate) {
        this.objectMdl.load(this.$route.params.id, this.onLoaded);
      } else {
        this.kegiatan.foto = null;
        this.uploadPhoto.requestAWS();
      }
    },
    resize(foto) {
      this.uploadPhoto.resize(
        foto, (foto, url) => {
          this.foto = foto;
          this.kegiatan.foto = url;
          this.validateInput("foto");
        });
    },
    upload() {
      this.uploadPhoto.upload(
        this.foto, this.saveObject, () => {
          this.kegiatan.foto = null;
          this.foto = null;
          this.fotoMdl = null;
        });
    },
    onSaved(respObj) {
      if (this.isCreate) {
        this.$router.replace({
          name: "kegiatan-mahasiswa-detail",
          params: { id: respObj.id }
        });
      } else {
        this.$router.go(-1);
      }
    },
    saveObject(commit) {
      this.objectMdl.setCommit(commit);
      // this.kegiatan.pspd = this.userId;
      if (commit) {
        this.objectMdl.save(this.saveContext, this.onSaved);
      } else {
        this.objectMdl.save(this.saveContext, this.upload);
      }
    }
  },
  watch: {
    kegiatan: {
      // kegiatan berubah, update edited
      handler(newValue, oldValue) {
        if (!this.kegiatanMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 769px), print {
  .field-label {
    flex-grow: 0;
    text-align: left;
  }
}

::v-deep .ac-field {
  button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  p.help {
    display: none;
  }
}
</style>
